import { Button } from '@hexa-ui/components-latest';
import { useFeatureToggleV2 } from 'admin-portal-shared-services';
import { APPLICATION_TITLE, PERSON_REGISTRATION } from 'constants/';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Allocations from '../EmployeeForm/Allocations';
import useCancelDialog from '../EmployeeForm/hooks/useCancelDialog';
import { BoxButtons, Container } from './styles';

const EditAllocation = () => {
  const navigate = useNavigate();

  const [personRegistrationFTStatus, personRegistrationFTIsLoading] = useFeatureToggleV2(
    PERSON_REGISTRATION,
    APPLICATION_TITLE
  );

  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);

  const { showCancelDialog } = useCancelDialog();

  useEffect(() => {
    if (!personRegistrationFTIsLoading && !personRegistrationFTStatus) navigate(-1);
  }, [personRegistrationFTStatus, personRegistrationFTIsLoading]);

  return (
    <Container elevated="medium" border="medium">
      <Allocations setDisableContinue={setDisableSubmit} />
      <BoxButtons>
        <Button
          type="button"
          data-testid="cancel-button"
          variant="secondary"
          onClick={() => showCancelDialog({ isAllocation: true })}
        >
          Cancel
        </Button>
        <Button
          form={`form-2`}
          type="submit"
          data-testid="save-button"
          variant="primary"
          disabled={disableSubmit}
        >
          Save
        </Button>
      </BoxButtons>
    </Container>
  );
};

export default EditAllocation;
