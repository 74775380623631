import {
  useFeatureToggleV2,
  useIsFeatureToggleServiceReady,
  usePermissionService,
  useSidebar,
} from 'admin-portal-shared-services';
import { APPLICATION_TITLE, NEW_ADD_PERSON_FORM, PERSON_REGISTRATION } from 'constants/';
import LoadingPage from 'pages/LoadingPage';
import { useAuth } from 'providers';
import { useAppHeader } from 'providers/AppHeaderProvider';
import { siderbarItems } from 'providers/SidebarConfigProvider/settings';
import { Suspense, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { routes, RouteType } from './utils/routes';

const Router = (): JSX.Element => {
  const { setOptionalButtons } = useAppHeader();
  const { userHasDevParametersPermission } = useAuth();
  const location = useLocation();
  const permissionService = usePermissionService();
  const isFeatureToggleServiceReady = useIsFeatureToggleServiceReady() !== false;

  const [newAddPersonFormFTStatus, newAddPersonFormFTIsLoading] = useFeatureToggleV2(
    NEW_ADD_PERSON_FORM,
    APPLICATION_TITLE
  );

  const [newEditPersonFormFTStatus, newEditPersonFormFTIsLoading] = useFeatureToggleV2(
    PERSON_REGISTRATION,
    APPLICATION_TITLE
  );

  const [isNewEmployeeAddFormEnabled, setIsNewEmployeeAddFormEnabled] = useState<boolean>(false);
  const [isNewEmployeeEditFormEnabled, setIsNewEmployeeEditFormEnabled] = useState<boolean>(false);
  const [previousPathname, setPreviousPathname] = useState<string>(null);

  const memoListMenuWithAccess = useMemo(() => {
    const filteredItemsByPermissions = siderbarItems.filter((listItem) => {
      if (!userHasDevParametersPermission && listItem.id === 'devs-item') return;
      if (listItem.scopes) return permissionService.hasOneOfScopes(listItem.scopes);

      return listItem.id === 'home-item';
    });

    return filteredItemsByPermissions;
  }, [userHasDevParametersPermission, siderbarItems, permissionService]);

  const memoSidebarParams = useMemo(
    () => ({
      items: memoListMenuWithAccess,
      utils: [],
    }),
    [memoListMenuWithAccess]
  );

  // remove this function when FT NEW_ADD_PERSON_FORM and PERSON_REGISTRATION is removed
  const filteredRoutesByAddAndEditFT: RouteType[] = useMemo(() => {
    const routeMap = {};

    routes.forEach((route) => {
      if (!routeMap[route.path]) {
        //add route to map, if it does not exist
        routeMap[route.path] = route;
      } else {
        // Check if the route should replace the existing one based on the value of addToBeRemoved
        const existingRoute = routeMap[route.path];

        //remove this block when FT NEW_ADD_PERSON_FORM is removed
        if (isNewEmployeeAddFormEnabled) {
          // If FT is enabled, keep the route with addToBeRemoved: false
          if (existingRoute.addToBeRemoved !== false && route.addToBeRemoved === false) {
            routeMap[route.path] = route;
          }
        } else {
          // If FT is disabled, keep the route with addToBeRemoved: true
          if (existingRoute.addToBeRemoved !== true && route.addToBeRemoved === true) {
            routeMap[route.path] = route;
          }
        }

        //remove this block when FT PERSON_REGISTRATION is removed
        if (isNewEmployeeEditFormEnabled) {
          // If FT is enabled, keep the route with editToBeRemoved: false
          if (existingRoute.editToBeRemoved !== false && route.editToBeRemoved === false) {
            routeMap[route.path] = route;
          }
        } else {
          // If FT is disabled, keep the route with editToBeRemoved: true
          if (existingRoute.editToBeRemoved !== true && route.editToBeRemoved === true) {
            routeMap[route.path] = route;
          }
        }
      }
    });

    return Object.values(routeMap);
  }, [routes, isNewEmployeeAddFormEnabled, isNewEmployeeEditFormEnabled]);

  useSidebar(memoSidebarParams);

  useLayoutEffect(() => {
    if (location.pathname !== previousPathname) setOptionalButtons(null);
    setPreviousPathname(location.pathname);
  }, [location]);

  useEffect(() => {
    if (!newAddPersonFormFTIsLoading) {
      setIsNewEmployeeAddFormEnabled(newAddPersonFormFTStatus);
    }
  }, [newAddPersonFormFTStatus, newAddPersonFormFTIsLoading]);

  useEffect(() => {
    if (!newEditPersonFormFTIsLoading) {
      setIsNewEmployeeEditFormEnabled(newEditPersonFormFTStatus);
    }
  }, [newEditPersonFormFTStatus, newEditPersonFormFTIsLoading]);

  return (
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        {isFeatureToggleServiceReady &&
          // when NEW_ADD_PERSON_FORM is removed, change filteredRoutesByAddPersonFT to routes
          filteredRoutesByAddAndEditFT.map(({ path, Component }, key) => (
            <Route path={path} key={key} element={Component} />
          ))}
      </Routes>
    </Suspense>
  );
};
export default Router;
