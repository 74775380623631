import createColumn from 'utils/table/createColumn';

type ColumnsConfigProps = {
  renderCellTooltipCard: (value: any) => JSX.Element;
  renderHeaderLastMonthAllocation: () => JSX.Element;
  renderCellLastMonthAllocation: (value: any) => JSX.Element;
  renderCellCurrentAllocation: (value: any) => JSX.Element;
  renderCellActions: (value: any) => JSX.Element;
  renderCellCopyValuesButton: (value: any) => JSX.Element;
  isInTransition: boolean;
};

export const TRANSITION_COLOR = '#FFAA33';

export const columnsConfig = ({
  renderCellTooltipCard,
  renderHeaderLastMonthAllocation,
  renderCellLastMonthAllocation,
  renderCellCurrentAllocation,
  renderCellActions,
  renderCellCopyValuesButton,
  isInTransition,
}: ColumnsConfigProps) => [
  createColumn({
    columnName: 'POD',
    accessor: 'teamName',
    color: isInTransition && TRANSITION_COLOR,
  }),
  {
    id: 'tooltipCard',
    Cell: (value) => renderCellTooltipCard(value),
    disableSortBy: true,
  },
  {
    Header: () => renderHeaderLastMonthAllocation(),
    accessor: 'lastMonthAllocation',
    alignment: 'right',
    Cell: (value) => renderCellLastMonthAllocation(value),
    disableSortBy: true,
  },
  {
    accessor: 'copyValuesButton',
    alignment: 'right',
    style: {
      width: 100,
    },
    Cell: (value) => renderCellCopyValuesButton(value),
    disableSortBy: true,
  },
  {
    Header: 'Current allocation',
    accessor: 'allocation',
    alignment: 'right',
    style: {
      width: 160,
    },
    Cell: (value) => renderCellCurrentAllocation(value),
    disableSortBy: true,
  },
  {
    accessor: 'actions',
    alignment: 'center',
    disableSortBy: true,
    style: {
      width: 100,
    },
    Cell: (value) => renderCellActions(value),
  },
];
