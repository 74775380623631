import { Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const StyledCellParagraph = styled(Paragraph, {
  variants: {
    isInactive: {
      true: {
        opacity: 0.3,
      },
    },
    isInTransition: {
      true: {
        color: '#FFB042 !important',
      },
    },
    isGreen: {
      true: {
        color: '$semanticSuccessBasis !important',
      },
    },
    textAlign: {
      right: {
        textAlign: 'right !important',
      },
      left: {
        textAlign: 'left !important',
      },
    },
  },
});

export { StyledCellParagraph };
