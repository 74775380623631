import { Input, Paragraph, SearchField } from '@hexa-ui/components';
import { useCombobox } from 'downshift';
import { useEffect, useState } from 'react';

import DetailedListItem from './components/DetailedListItem';

import {
  ComboBoxContainer,
  SearchAutoCompleteContainer,
  SearchAutoCompleteListbox,
  SearchAutoCompleteListBoxFooter,
  SearchAutoCompleteListBoxHeader,
  SearchAutoCompleteListItem,
  SearchFieldContainer,
} from './styles';

import { DetailedListProps, SearchAutoCompleteProps } from './types';
import { verifyIfOptionLabelIncludesValue } from './utils';

export const SearchAutoComplete = <T,>({
  optionsList,
  optionLabel,
  label = '',
  placeholder = 'Search',
  size = 'medium',
  type = 'search',
  comboDataType = 'simpleList',
  required = false,
  clearInput,
  hasError,
  error,
  isServerFiltering = false,
  onInputValueChange,
  onSelectedItemChange,
  disabled,
}: SearchAutoCompleteProps<T>) => {
  const [items, setItems] = useState(optionsList);
  const [inputValue, setInputValue] = useState<string>('');

  const isMultipleOptionLabel = Array.isArray(optionLabel);

  useEffect(() => {
    onClear();
  }, [clearInput]);

  useEffect(() => {
    if (!isServerFiltering) {
      if (onInputValueChange && typeof onInputValueChange === 'function')
        onInputValueChange(inputValue);
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      if (inputValue.length || inputValue.length === 0) onInputValueChange(inputValue);
    }, 800);

    return () => clearTimeout(delayDebounceFn);
  }, [inputValue]);

  const keepFilteredItems = (value: string, isOpen: boolean) => {
    const inputHasValue = value !== '';
    const filteredOptionsList = optionsList.filter(getOptionsListFilter(value));

    if (isOpen && inputHasValue) {
      setItems(filteredOptionsList);
    }
  };

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectItem,
    closeMenu,
  } = useCombobox({
    onInputValueChange({ inputValue }) {
      if (!isServerFiltering) {
        const value = Array.isArray(inputValue) ? inputValue[0] : inputValue;
        setItems(optionsList.filter(getOptionsListFilter(value)));
      }
      setInputValue(inputValue);
    },
    items,
    itemToString(selectedItem: T) {
      if (!selectedItem) return '';
      if (isMultipleOptionLabel) return selectedItem[optionLabel[0]];
      else return selectedItem[optionLabel];
    },
    onSelectedItemChange({ selectedItem: newSelectedItem }) {
      onSelectedItemChange(newSelectedItem);
    },
    onIsOpenChange: (changes) => {
      const { inputValue, isOpen } = changes;
      keepFilteredItems(inputValue, isOpen);
    },
  });

  const getOptionsListFilter = (inputValue: string) => {
    return (item: T) => {
      if (isMultipleOptionLabel) {
        return (
          !inputValue ||
          verifyIfOptionLabelIncludesValue(item['title'], inputValue) ||
          verifyIfOptionLabelIncludesValue(item['description'], inputValue)
        );
      }
      return !inputValue || verifyIfOptionLabelIncludesValue(item[optionLabel], inputValue);
    };
  };

  const onClear = () => {
    selectItem(undefined);
    onSelectedItemChange('');
  };
  // handleOnSearch is called when user searches clicking enter key
  const handleOnSearch = (val: string) => {
    const searchedItem = isMultipleOptionLabel ? { [optionLabel[1]]: val } : { [optionLabel]: val };

    onSelectedItemChange(searchedItem);
    closeMenu();
  };

  useEffect(() => {
    setItems(optionsList);
  }, [optionsList, selectItem]);

  const renderSearchField = () => (
    <SearchField.Root
      disabled={disabled}
      width={'100%'}
      placeholder={placeholder}
      onClear={() => onClear()}
      {...getInputProps({ itemRef: 'searchFieldRef' })}
      onSearch={(val) => handleOnSearch(val)}
    />
  );

  const renderInputField = () => (
    <Input
      {...getInputProps({ itemRef: 'inputFieldRef' })}
      placeholder={placeholder}
      required={required}
      errorText={error}
      hasError={!!hasError}
      width="100%"
      label={label}
      disabled={disabled}
    />
  );
  const renderSimpleList = (item: T, index: number) => (
    <Paragraph id={`${index}`}>{!isMultipleOptionLabel && item[optionLabel]}</Paragraph>
  );

  const renderDetailedList = (item: DetailedListProps) => <DetailedListItem {...item} />;
  const isSearchField = type === 'search';

  return (
    <SearchAutoCompleteContainer size={size} {...getToggleButtonProps()}>
      <SearchFieldContainer {...getComboboxProps()}>
        {isSearchField ? renderSearchField() : renderInputField()}
      </SearchFieldContainer>

      <ComboBoxContainer
        isOpen={isOpen && items?.length > 0}
        hasError={hasError}
        full={size === 'full'}
        {...getToggleButtonProps()}
      >
        <SearchAutoCompleteListBoxHeader />
        <SearchAutoCompleteListbox {...getMenuProps({ itemRef: 'ulRef' })}>
          {isOpen &&
            items?.map((item, index) => (
              <SearchAutoCompleteListItem
                key={`${item}${index}`}
                {...getItemProps({ item, index })}
                selected={highlightedIndex === index}
              >
                {comboDataType === 'simpleList' && renderSimpleList(item, index)}
                {comboDataType === 'detailedList' &&
                  renderDetailedList(item as unknown as DetailedListProps)}
              </SearchAutoCompleteListItem>
            ))}
        </SearchAutoCompleteListbox>
        <SearchAutoCompleteListBoxFooter />
      </ComboBoxContainer>
    </SearchAutoCompleteContainer>
  );
};
export default SearchAutoComplete;
