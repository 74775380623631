import { STATUS } from 'constants/status';
import { StyledCellParagraph } from './styles';

interface CellParagraphProps {
  value: any;
  status?: string;
  isGreen?: boolean;
  id?: string;
  textAlign?: 'right' | 'left';
  customColor?: string;
}

export const CellParagraph = ({
  value,
  status,
  isGreen,
  id,
  textAlign,
  customColor,
}: CellParagraphProps) => {
  const isEmployeePage = window.location.pathname === '/capacity-management/person';
  const isPODPage = window.location.pathname.includes('/capacity-management/pod');

  return (
    <StyledCellParagraph
      id={id}
      isGreen={isGreen}
      isInactive={status === STATUS.Inactive}
      isInTransition={(isPODPage || isEmployeePage) && status === STATUS.InTransition}
      textAlign={textAlign}
      css={customColor ? { color: customColor } : {}}
    >
      {value ?? '-'}
    </StyledCellParagraph>
  );
};

export default CellParagraph;
