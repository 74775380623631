import { generatePath } from 'react-router-dom';

export const EMPLOYEES_ROUTES = {
  employees: '/employees',
  reactivateEmployeeById: {
    route: '/employees/:employeeId/reactivate',
    generate(employeeId) {
      return generatePath(this.route, { employeeId });
    },
  },
  employeeById: {
    route: '/employees/:employeeId',
    generate(employeeId) {
      return generatePath(this.route, { employeeId });
    },
  },
  employeeExportAllocation: '/employees/history/export/allocation',
  employeeAllocationLimitDates: '/employees/history/allocation/limitDates',
  employeeLastMonthAllocation: '/employees/lastMonthAvailable',
  employeeExists: '/employees/exists',
  employeeExistsByEmail: '/employees/existsEmail',
  employeeExistsByNumber: '/employees/existsNumber',
  employeesFiltersOptions: '/employees/filters/options',
  employeesFiltersSearch: '/employees/filters/search',
  employeesPagination: '/employees/filters',
  directors: '/employees/directors',
  allocationsByEmployeeId: {
    route: '/employees/:employeeId/allocations',
    generate(employeeId) {
      return generatePath(this.route, { employeeId });
    },
  },
  inactivateEmployee: {
    route: '/employees/:employeeId/inactivate',
    generate(employeeId) {
      return generatePath(this.route, { employeeId });
    },
  },
  employeesHistoryTeams: {
    route: '/employees/:employeeId/history/teams',
    generate(employeeId) {
      return generatePath(this.route, { employeeId });
    },
  },
  employeesHistoryRangeDates: {
    route: '/employees/:employeeId/history/rangeDates',
    generate(employeeId) {
      return generatePath(this.route, { employeeId });
    },
  },
  employeesHistoryDateFilter: {
    route: '/employees/:employeeId/history',
    generate(employeeId) {
      return generatePath(this.route, { employeeId });
    },
  },
  employeesHistoryLogs: {
    route: '/employees/:employeeId/logs',
    generate(employeeId) {
      return generatePath(this.route, { employeeId });
    },
  },
  employeeRoleAndName: {
    route: '/employees/:employeeId/roleAndName',
    generate(employeeId) {
      return generatePath(this.route, { employeeId });
    },
  },
  employeeUpdateInformation: {
    route: '/employees/:employeeId/allocations/updateInformation',
    generate(employeeId) {
      return generatePath(this.route, { employeeId });
    },
  },
  employeeTransition: {
    route: '/employees/:employeeId/transition',
    generate(employeeId) {
      return generatePath(this.route, { employeeId });
    },
  },
  bulkPeopleTemplate: '/bulk/employees/template',
  guardianInfo: {
    route: '/employees/:employeeId/guardianInfo',
    generate(employeeId) {
      return generatePath(this.route, { employeeId });
    },
  },
};
