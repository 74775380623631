export const VERTICAL_GO_TO_STRUCTURES = 'vertical-go-to-structure';
export const FILTERED_REPORT_ALLOCATION = 'people-management-full-history-filter';
export const ADD_STRUCTURE_BY_VERTICAL_FORM = 'add-structure-by-vertical-form';
export const NEW_ADD_PERSON_FORM = 'new-add-person-form';
export const PERSON_REGISTRATION = 'person-registration';
export const VALUE_STREAM_MANAGEMENT_FILTER_GUARDIANS = 'value-stream-management-filter-guardians';
export const POD_MANAGEMENT_POD_GUARDIAN_FILTER = 'pod-management-pod-guardian-filter';
export const ADMIN_WORKDAY_BULK_UPLOAD = 'admin-workday-bulk-upload';
export const EDIT_PERSON_3_FIELDS_PERMISSION = 'edit-person-3-fields-permission';
export const EXTERNAL_EMPLOYEE_ADD_EDIT_LINE_MANAGER = 'external-employee-add-edit-line-manager';
