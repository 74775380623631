import { BASE_NAME } from 'constants/appRoutes';
import { SCOPES } from 'constants/scopes';
import EditAllocation from 'pages/Employee/EditAllocations';
import { lazy } from 'react';
import PrivateRoute from 'routes/components/PrivateRoute';

const Home = lazy(() => import('../../pages/Home'));
const Admin = lazy(() => import('../../pages/Admin'));
const Devs = lazy(() => import('../../pages/Devs'));
const Parameters = lazy(() => import('../../pages/Parameters'));
const RoleManagement = lazy(() => import('../../pages/RoleManagement'));
const Company = lazy(() => import('../../pages/CompanyManagement/Company'));
const CompanyManagement = lazy(() => import('../../pages/CompanyManagement'));
const CompanyForm = lazy(() => import('../../pages/CompanyManagement/CompanyForm'));
const TeamManagement = lazy(() => import('../../pages/Team/TeamManagement'));
const TeamForm = lazy(() => import('../../pages/Team/TeamForm'));
const Team = lazy(() => import('../../pages/Team/Team'));
const OldEmployeeForm = lazy(() => import('../../pages/Employee/OldEmployeeForm'));
const EmployeeHistory = lazy(() => import('../../pages/Employee/EmployeeHistory'));
const EmployeeRegistration = lazy(() => import('../../pages/Employee/EmployeeRegistration'));
const EmployeeManagement = lazy(() => import('../../pages/Employee/EmployeeManagement'));
const ValueStreamManagement = lazy(() => import('../../pages/ValueStream/ValueStreamManagement'));
const ValueStreamForm = lazy(() => import('../../pages/ValueStream/ValueStreamForm'));
const ValueStream = lazy(() => import('../../pages/ValueStream/ValueStream'));
const VerticalManagement = lazy(() => import('../../pages/VerticalManagement'));
const VerticalForm = lazy(() => import('../../pages/VerticalManagement/VerticalForm'));
const Vertical = lazy(() => import('../../pages/VerticalManagement/Vertical'));
const CapacityManagement = lazy(() => import('../../pages/Capacity/CapacityManagement'));
const PlanningAllocation = lazy(() => import('../../pages/Capacity/PlanningAllocation'));
const StructureManagement = lazy(() => import('../../pages/Structure/StructureManagement'));
const StructureForm = lazy(() => import('../../pages/Structure/StructureForm'));
const Structure = lazy(() => import('../../pages/Structure/Structure'));
const MeetingManagement = lazy(() => import('../../pages/MeetingManagement'));
const Error403Page = lazy(() => import('../../pages/Error/403'));
const EditGuardianForm = lazy(() => import('../../pages/Team/PodGuardianForm'));
const ValueStreamTypesManagement = lazy(
  () => import('../../pages/ValueStreamTypes/ValueStreamTypesManagement')
);
const ValueStreamTypes = lazy(
  () => import('../../pages/ValueStreamTypes/ValueStreamTypesRegistration')
);
const ValueStreamTypesForm = lazy(
  () => import('../../pages/ValueStreamTypes/ValueStreamTypesForm')
);
const EmployeeForm = lazy(() => import('../../pages/Employee/EmployeeForm'));
const BulkWorkday = lazy(() => import('../../pages/BulkWorkday'));
const BulkWorkdayHistory = lazy(() => import('../../pages/BulkWorkday/History'));

export type RouteType = {
  path: string;
  name?: string;
  scopes?: string[];
  Component: JSX.Element;
  addToBeRemoved?: boolean;
  editToBeRemoved?: boolean;
};

export const routes: RouteType[] = [
  {
    path: `${BASE_NAME}`,
    Component: <Home />,
  },
  {
    path: `${BASE_NAME}/admin`,
    name: 'Admin',
    Component: <PrivateRoute route={<Admin />} />,
    scopes: [SCOPES.ADMIN_READ],
  },
  {
    path: `${BASE_NAME}/admin/role-management`,
    name: 'Role Management',
    Component: <PrivateRoute route={<RoleManagement />} />,
    scopes: [SCOPES.ROLE_READ],
  },
  {
    path: `${BASE_NAME}/admin/meeting-management`,
    name: 'Meeting Management',
    Component: <PrivateRoute route={<MeetingManagement />} />,
    scopes: [SCOPES.MEETING_READ],
  },
  {
    path: `${BASE_NAME}/admin/company-management`,
    name: 'Company Management',
    Component: <PrivateRoute route={<CompanyManagement />} />,
    scopes: [SCOPES.COMPANY_READ],
  },
  {
    path: `${BASE_NAME}/admin/company-management/add`,
    name: 'Add Company',
    Component: <PrivateRoute route={<CompanyForm />} />,
    scopes: [SCOPES.COMPANY_CREATE],
  },
  {
    path: `${BASE_NAME}/admin/company-management/:companyId/edit`,
    name: 'Edit Company',
    Component: <PrivateRoute route={<CompanyForm />} />,
    scopes: [SCOPES.COMPANY_UPDATE],
  },
  {
    path: `${BASE_NAME}/admin/company-management/:companyId`,
    name: 'Company Registration',
    Component: <PrivateRoute route={<Company />} />,
    scopes: [SCOPES.COMPANY_READ],
  },
  {
    path: `${BASE_NAME}/pod`,
    name: 'POD Management',
    Component: <PrivateRoute route={<TeamManagement />} />,
    scopes: [SCOPES.TEAM_READ],
  },
  {
    path: `${BASE_NAME}/pod/:podId`,
    name: 'POD Registration',
    Component: <PrivateRoute route={<Team />} />,
    scopes: [SCOPES.TEAM_READ],
  },
  {
    path: `${BASE_NAME}/pod/:podId/edit`,
    name: 'Edit POD',
    Component: <PrivateRoute route={<TeamForm />} />,
    scopes: [SCOPES.TEAM_UPDATE],
  },
  {
    path: `${BASE_NAME}/pod/:podId/guardian`,
    name: 'Edit POD Guardian',
    Component: <PrivateRoute route={<EditGuardianForm />} />,
    scopes: [SCOPES.TEAM_UPDATE_GUARDIAN],
  },
  {
    path: `${BASE_NAME}/pod/add`,
    name: 'Add POD',
    Component: <PrivateRoute route={<TeamForm />} />,
    scopes: [SCOPES.TEAM_CREATE],
  },
  {
    path: `${BASE_NAME}/person`,
    name: 'People Management',
    Component: <PrivateRoute route={<EmployeeManagement />} />,
    scopes: [SCOPES.PEOPLE_READ_ALL, SCOPES.PEOPLE_READ_SELF],
  },
  {
    path: `${BASE_NAME}/person/:employeeId`,
    name: 'Person Registration',
    Component: <PrivateRoute route={<EmployeeRegistration />} />,
    scopes: [SCOPES.PEOPLE_READ],
  },
  {
    path: `${BASE_NAME}/person/:employeeId/edit`,
    name: 'Edit person',
    Component: <PrivateRoute route={<OldEmployeeForm />} />,
    scopes: [SCOPES.PEOPLE_UPDATE_ALL, SCOPES.PEOPLE_UPDATE_SELF],
    editToBeRemoved: true,
  },
  {
    path: `${BASE_NAME}/person/add`,
    name: 'Add person',
    Component: <PrivateRoute route={<OldEmployeeForm />} />,
    scopes: [SCOPES.PEOPLE_CREATE_ALL, SCOPES.PEOPLE_CREATE_SELF],
    addToBeRemoved: true,
  },
  {
    path: `${BASE_NAME}/person/:employeeId/history`,
    name: 'History',
    Component: <PrivateRoute route={<EmployeeHistory />} />,
    scopes: [SCOPES.PEOPLE_READ_ALL, SCOPES.PEOPLE_READ_SELF],
  },
  {
    path: `${BASE_NAME}/value-stream`,
    name: 'Value Stream Management',
    Component: <PrivateRoute route={<ValueStreamManagement />} />,
    scopes: [SCOPES.VALUE_STREAM_READ],
  },
  {
    path: `${BASE_NAME}/value-stream/:valueStreamId`,
    name: 'Value Stream Registration',
    Component: <PrivateRoute route={<ValueStream />} />,
    scopes: [SCOPES.VALUE_STREAM_READ],
  },
  {
    path: `${BASE_NAME}/value-stream/:valueStreamId/edit`,
    name: 'Edit Value Stream',
    Component: <PrivateRoute route={<ValueStreamForm />} />,
    scopes: [SCOPES.VALUE_STREAM_UPDATE],
  },
  {
    path: `${BASE_NAME}/value-stream/add`,
    name: 'Add Value Stream',
    Component: <PrivateRoute route={<ValueStreamForm />} />,
    scopes: [SCOPES.VALUE_STREAM_CREATE],
  },
  {
    path: `${BASE_NAME}/capacity`,
    name: 'Capacity Management',
    Component: <PrivateRoute route={<CapacityManagement />} />,
    scopes: [SCOPES.CAPACITY_MANAGEMENT_READ],
  },
  {
    path: `${BASE_NAME}/capacity/structure/:structureId/planning`,
    name: 'Planning Allocation',
    Component: <PrivateRoute route={<PlanningAllocation />} />,
    scopes: [SCOPES.CAPACITY_MANAGEMENT_READ],
  },
  {
    path: `${BASE_NAME}/structure`,
    name: 'Structure Management',
    Component: <PrivateRoute route={<StructureManagement />} />,
    scopes: [SCOPES.STRUCTURE_READ],
  },
  {
    path: `${BASE_NAME}/structure/add`,
    name: 'Add Structure',
    Component: <PrivateRoute route={<StructureForm />} />,
    scopes: [SCOPES.STRUCTURE_CREATE],
  },
  {
    path: `${BASE_NAME}/structure/:structureId`,
    name: 'Structure Registration',
    Component: <PrivateRoute route={<Structure />} />,
    scopes: [SCOPES.STRUCTURE_READ],
  },
  {
    path: `${BASE_NAME}/structure/:structureId/edit`,
    name: 'Edit Structure',
    Component: <PrivateRoute route={<StructureForm />} />,
    scopes: [SCOPES.STRUCTURE_UPDATE],
  },
  {
    path: `${BASE_NAME}/403`,
    Component: <Error403Page />,
  },
  {
    path: `${BASE_NAME}/devs`,
    name: 'Devs',
    Component: <PrivateRoute route={<Devs />} />,
    scopes: [SCOPES.DEVELOPER_READ],
  },
  {
    path: `${BASE_NAME}/devs/parameters`,
    name: 'Parameters',
    Component: <PrivateRoute route={<Parameters />} />,
    scopes: [SCOPES.PARAMETER_READ, SCOPES.PARAMETER_UPDATE],
  },
  {
    path: `${BASE_NAME}/admin/value-stream-type-management`,
    name: 'Value Stream Type Management',
    Component: <PrivateRoute route={<ValueStreamTypesManagement />} />,
    scopes: [SCOPES.VALUE_STREAM_TYPE_READ],
  },
  {
    path: `${BASE_NAME}/admin/value-stream-type-management/:valueStreamTypeId`,
    name: 'Value Stream Type Registration',
    Component: <PrivateRoute route={<ValueStreamTypes />} />,
    scopes: [SCOPES.VALUE_STREAM_TYPE_READ],
  },
  {
    path: `${BASE_NAME}/admin/value-stream-type-management/add`,
    name: 'Add Value Stream Type',
    Component: <PrivateRoute route={<ValueStreamTypesForm />} />,
    scopes: [SCOPES.VALUE_STREAM_TYPE_WRITE],
  },
  {
    path: `${BASE_NAME}/admin/value-stream-type-management/:valueStreamTypeId/edit`,
    name: 'Edit Value Stream Type',
    Component: <PrivateRoute route={<ValueStreamTypesForm />} />,
    scopes: [SCOPES.VALUE_STREAM_TYPE_UPDATE],
  },
  {
    path: `${BASE_NAME}/vertical`,
    name: 'Vertical Management',
    Component: <PrivateRoute route={<VerticalManagement />} />,
    scopes: [SCOPES.VERTICAL_READ],
  },
  {
    path: `${BASE_NAME}/vertical/add`,
    name: 'Add Vertical',
    Component: <PrivateRoute route={<VerticalForm />} />,
    scopes: [SCOPES.VERTICAL_WRITE],
  },
  {
    path: `${BASE_NAME}/vertical/:verticalId`,
    name: 'Vertical Registration',
    Component: <PrivateRoute route={<Vertical />} />,
    scopes: [SCOPES.VERTICAL_READ],
  },
  {
    path: `${BASE_NAME}/vertical/:verticalId/edit`,
    name: 'Edit Vertical',
    Component: <PrivateRoute route={<VerticalForm />} />,
    scopes: [SCOPES.VERTICAL_UPDATE],
  },
  {
    path: `${BASE_NAME}/person/add`,
    name: 'Add person',
    Component: <PrivateRoute route={<EmployeeForm />} />,
    scopes: [SCOPES.PEOPLE_CREATE_ALL, SCOPES.PEOPLE_CREATE_SELF],
    addToBeRemoved: false,
  },
  {
    path: `${BASE_NAME}/person/:employeeId/edit`,
    name: 'Edit person',
    Component: <PrivateRoute route={<EmployeeForm />} />,
    scopes: [SCOPES.PEOPLE_UPDATE_ALL, SCOPES.PEOPLE_UPDATE_SELF],
    editToBeRemoved: false,
  },
  {
    path: `${BASE_NAME}/person/:employeeId/reactivate`,
    name: 'Reactivate person',
    Component: <PrivateRoute route={<EmployeeForm />} />,
    scopes: [SCOPES.PEOPLE_UPDATE_ALL, SCOPES.PEOPLE_UPDATE_SELF, SCOPES.PEOPLE_REACTIVATE],
    editToBeRemoved: false,
  },
  {
    path: `${BASE_NAME}/person/:employeeId/editAllocation`,
    name: 'Edit Allocation',
    Component: <PrivateRoute route={<EditAllocation />} />,
    scopes: [SCOPES.PEOPLE_UPDATE_ALLOCATION],
  },
  {
    path: `${BASE_NAME}/admin/bulk-workday`,
    name: 'Workday Information',
    Component: <PrivateRoute route={<BulkWorkday />} />,
    scopes: [SCOPES.BULK_WORKDAY_READ],
  },
  {
    path: `${BASE_NAME}/admin/bulk-workday/history`,
    name: 'History',
    Component: <PrivateRoute route={<BulkWorkdayHistory />} />,
    scopes: [SCOPES.BULK_WORKDAY_READ],
  },
];
