import { formatNumber } from 'utils/generic';
import CellParagraph from '.';

interface CreateColumnProps {
  id?: string;
  columnName?: string | React.ReactNode;
  accessor?: string;
  amount?: number;
  alignment?: string;
  style?: any;
  width?: string;
  color?: string;
}

const isNumericValue = (value: number) => typeof value === 'number';

const createColumn = ({
  id,
  columnName,
  accessor,
  amount,
  alignment = 'left',
  style = {},
  width = '25%',
  color,
}: CreateColumnProps) => {
  const header = isNumericValue(amount) ? `${columnName} (${amount})` : columnName;

  return {
    id: accessor || id,
    Header: header || '',
    style: {
      ...style,
      width,
    },
    Cell: ({ row }) => {
      const { original } = row;
      const columnValue = original[accessor];

      if (!accessor) return '';

      return (
        <CellParagraph
          value={isNumericValue(columnValue) ? formatNumber(columnValue).toFixed(2) : columnValue}
          status={original.situationStatus}
          textAlign={alignment === 'right' ? 'right' : null}
          customColor={color}
        />
      );
    },
    alignment,
    disableSortBy: true,
  };
};

export default createColumn;
