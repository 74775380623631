import { useLogService as logService } from 'admin-portal-shared-services';
import {
  CAPACITY_BFF_PATH,
  EMPLOYEES_ROUTES,
  ROLES_ROUTES,
  SUCCESS_CODE,
  TEAM_ROUTES,
} from 'constants/rest';
import { downloadFileFromRoute } from 'utils/files';
import Api from '../../Api/Api';
import {
  CreateEmployeeRequest,
  DownloadEmployeeAllocationHistoryRequest,
  DownloadEmployeeAllocationRequest,
  EmployeeAllocationLimitDatesResponse,
  EmployeeLastMonthAvailableResponse,
  EmployeePaginationFilters,
  EmployeePaginationFiltersResponse,
  EmployeePaginationFiltersSearchResponse,
  EmployeeTransitionRequest,
  EmployeeUpdateInformationResponse,
  EmployeeView,
  EmployeesRolesResponse,
  EmployeesRolesUpdateRequest,
  GetAllocationsByEmployeeResponse,
  GetEmployeeByIdResponse,
  GetEmployeeGuardianInfoResponse,
  GetEmployeeHistoryLogsResponse,
  GetEmployeeHistoryRequest,
  GetEmployeeHistoryResponse,
  GetEmployeeRoleAndNameResponse,
  GetEmployeesByTeamRequest,
  GetEmployeesHistoryRangeDatesResponse,
  GetEmployeesHistoryTeamsResponse,
  GetEmployeesResponse,
  UpdateAllocationsRequest,
} from './EmployeeService.types';

const log = logService();

export const createEmployee = async <EmployeeView>(
  newEmployee: CreateEmployeeRequest
): Promise<EmployeeView> => {
  const response = await Api.post({
    url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.employees}`,
    body: newEmployee,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('post-create-new-employee-error', response);
      reject(response);
    }
  });
};

export const updateEmployee = async <EmployeeView>(
  newEmployee: CreateEmployeeRequest
): Promise<EmployeeView> => {
  const response = await Api.put({
    url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.employees}`,
    body: newEmployee,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('post-update-employee-error', response);
      reject(response);
    }
  });
};

export const updateEmployeeById = async <EmployeeView>(
  newEmployee: CreateEmployeeRequest
): Promise<EmployeeView> => {
  const response = await Api.put({
    url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.employeeById.generate(newEmployee.id)}`,
    body: newEmployee,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('post-update-employee-error', response);
      reject(response);
    }
  });
};

export const reactivateEmployeeById = async <EmployeeView>(
  employee: CreateEmployeeRequest
): Promise<EmployeeView> => {
  const response = await Api.put({
    url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.reactivateEmployeeById.generate(employee.id)}`,
    body: employee,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('reactivate-employee-error', response);
      reject(response);
    }
  });
};

export const employeeExists = async (): Promise<number | null> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.employeeExists}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data?.id);
    } else {
      log.error('get-employee-with-id-error', response);
      reject(response);
    }
  });
};

export const employeeExistsByEmail = async (value: string, id?: number): Promise<boolean> => {
  if (!value) return;

  let url = `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.employeeExistsByEmail}?email=${value}`;
  if (id) url += `&id=${id}`;

  const response = await Api.get({
    url,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data.exists);
    } else {
      log.error('get-verify-employee-email-error', response);
      reject(response);
    }
  });
};

export const employeeExistsByNumber = async (value: number | string): Promise<boolean> => {
  if (!value) return;

  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.employeeExistsByNumber}?employeeNumber=${value}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data.exists);
    } else {
      log.error('get-verify-employee-number-error', response);
      reject(response);
    }
  });
};

export const getEmployeesByTeam = async <EmployeePaginationResponse>({
  pagination,
  teamId,
}: GetEmployeesByTeamRequest): Promise<EmployeePaginationResponse> => {
  const response = await Api.post({
    url: `${CAPACITY_BFF_PATH}${TEAM_ROUTES.employeesByTeam.generate(teamId)}`,
    body: {
      pagination,
    },
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-employees-by-team-error', response);
      reject(response);
    }
  });
};

export const getEmployeesWithPagination = async <EmployeePaginationResponse>(
  pagination: Pagination,
  filters: EmployeePaginationFilters = {},
  onlyWithoutTeam: boolean = false
): Promise<EmployeePaginationResponse> => {
  const body = {
    filters,
    pagination,
    onlyWithoutTeam,
  };

  const response = await Api.post({
    url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.employeesPagination}`,
    body,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-employees-pagination-error', response);
      reject(response);
    }
  });
};

export const getEmployeesFiltersOptions = async <EmployeePaginationFiltersResponse>(
  filters: EmployeePaginationFilters = {},
  onlyWithoutTeam: boolean = false
): Promise<EmployeePaginationFiltersResponse> => {
  const body = {
    filters,
    onlyWithoutTeam,
  };

  const response = await Api.post({
    url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.employeesFiltersOptions}`,
    body,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-employees-filters-options-error', response);
      reject(response);
    }
  });
};

export const getEmployeesFiltersSearch = async <EmployeePaginationFiltersSearchResponse>(
  filters: EmployeePaginationFilters = {},
  onlyWithoutTeam: boolean = false
): Promise<EmployeePaginationFiltersSearchResponse> => {
  const body = {
    filters,
    onlyWithoutTeam,
  };

  const response = await Api.post({
    url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.employeesFiltersSearch}`,
    body,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-employees-filters-search-error', response);
      reject(response);
    }
  });
};

export const getEmployeesWithPaginationAndFilters = async <EmployeePaginationResponse>(
  pagination: Pagination,
  filters: EmployeePaginationFilters = {},
  onlyWithoutTeam: boolean = false
): Promise<EmployeePaginationResponse> => {
  const requests: [
    Promise<EmployeePaginationFiltersResponse>,
    Promise<EmployeePaginationFiltersSearchResponse>,
    Promise<EmployeePaginationResponse>
  ] = [
    getEmployeesFiltersOptions(filters, onlyWithoutTeam),
    getEmployeesFiltersSearch(filters, onlyWithoutTeam),
    getEmployeesWithPagination(pagination, filters, onlyWithoutTeam),
  ];
  return new Promise(async (resolve, reject) => {
    try {
      const responses = await Promise.all(requests);

      const response = {
        ...responses[2],
        filters: {
          ...responses[0].filters,
          ...responses[1],
        },
        onlyWithoutTeam,
      };

      return resolve(response);
    } catch (error) {
      log.error('get-employees-pagination-and-filters-error', error);
      return reject(error);
    }
  });
};

export const getEmployeeById = async (
  employeeId: number
  // remove EmployeeView when feature toggle person-registration (PERSON_REGISTRATION) is removed
): Promise<EmployeeView | GetEmployeeByIdResponse> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.employeeById.generate(employeeId)}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-employee-error', response);
      reject(response);
    }
  });
};

export const getAllocationsByEmployeeId = async (
  employeeId: number
): Promise<GetAllocationsByEmployeeResponse> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.allocationsByEmployeeId.generate(employeeId)}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-employees-by-team-error', response);
      reject(response);
    }
  });
};

export const getEmployees = async (search?: string): Promise<GetEmployeesResponse> => {
  const config = {
    params: {
      search,
    },
  };

  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.employees}`,
    config,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-employees', response);
      reject(response);
    }
  });
};

export const updateAllocationsByEmployeeId = async <EmployeeView>({
  employeeId,
  allocations,
}: UpdateAllocationsRequest): Promise<EmployeeView> => {
  const body = {
    allocations,
  };

  const response = await Api.put({
    url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.allocationsByEmployeeId.generate(employeeId)}`,
    body,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('update-allocations-by-employee-id', response);
      reject(response);
    }
  });
};

export const getDirectors = async <GetDirectorsResponse>(): Promise<GetDirectorsResponse> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.directors}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-directors-error', response);
      reject(response);
    }
  });
};

export const inactivateEmployee = async (employeeId: number, date: string): Promise<void> => {
  const response = await Api.patch({
    url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.inactivateEmployee.generate(
      employeeId
    )}?date=${date}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.status);
    } else {
      log.error('inactivate-employee-error', response);
      reject(response);
    }
  });
};

export const getEmployeesHistoryTeams = async (
  employeeId: number
): Promise<GetEmployeesHistoryTeamsResponse> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.employeesHistoryTeams.generate(employeeId)}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('employee-history-teams-error', response);
      reject(response);
    }
  });
};

export const getEmployeesHistoryRangeDates = async (
  employeeId: number
): Promise<GetEmployeesHistoryRangeDatesResponse> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.employeesHistoryRangeDates.generate(employeeId)}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('employee-history-range-dates-error', response);
      reject(response);
    }
  });
};

export const getEmployeesHistoryDateFilter = async ({
  employeeId,
  startMonth,
  endMonth,
}: GetEmployeeHistoryRequest): Promise<GetEmployeeHistoryResponse> => {
  const config = {
    params: {
      startMonth,
      endMonth,
    },
  };

  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.employeesHistoryDateFilter.generate(employeeId)}`,
    config,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-employee-history-date-filter-error', response);
      reject(response);
    }
  });
};

export const getEmployeesHistoryLogs = async (
  employeeId: number
): Promise<GetEmployeeHistoryLogsResponse> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.employeesHistoryLogs.generate(employeeId)}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('employee-history-logs-error', response);
      reject(response);
    }
  });
};

export const getEmployeeRoleAndName = async (
  employeeId: number
): Promise<GetEmployeeRoleAndNameResponse> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.employeeRoleAndName.generate(employeeId)}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('employee-role-and-name-error', response);
      reject(response);
    }
  });
};

export const getEmployeesLastMonthAvailable =
  async (): Promise<EmployeeLastMonthAvailableResponse> => {
    const response = await Api.get({
      url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.employeeLastMonthAllocation}`,
    });

    return new Promise((resolve, reject) => {
      if (SUCCESS_CODE.includes(response.status)) {
        resolve(response.data);
      } else {
        log.error(response, 'get-employees-last-month-available-error');
        reject(response);
      }
    });
  };

export const getEmployeesAllocationLimitDates =
  async (): Promise<EmployeeAllocationLimitDatesResponse> => {
    const response = await Api.get({
      url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.employeeAllocationLimitDates}`,
    });

    return new Promise((resolve, reject) => {
      if (SUCCESS_CODE.includes(response.status)) {
        resolve(response.data);
      } else {
        log.error(response, 'get-employees-allocation-limit-dates-error');
        reject(response);
      }
    });
  };

// remove service when FT FILTERED_REPORT_ALLOCATION is removed
export const downloadEmployeeAllocation = async (
  months: DownloadEmployeeAllocationRequest
): Promise<Response> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.employeeExportAllocation}`,
    config: {
      headers: {
        Accept: 'application/json, text/plain',
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer',
      params: months,
    },
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      downloadFileFromRoute(response);
      resolve(response.data);
    } else {
      const convertedResponseDataFromArrayBufferToJSON = JSON.parse(
        new TextDecoder().decode(response.response.data as ArrayBuffer)
      );

      log.error('download-employee-allocation-error', response);
      reject(convertedResponseDataFromArrayBufferToJSON);
    }
  });
};

export const downloadEmployeeAllocationHistory = async (
  params: DownloadEmployeeAllocationHistoryRequest
): Promise<Response> => {
  const response = await Api.post({
    url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.employeeExportAllocation}`,
    config: {
      headers: {
        Accept: 'application/json, text/plain',
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer',
    },
    body: { ...params },
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      downloadFileFromRoute(response);
      resolve(response.data);
    } else {
      const convertedResponseDataFromArrayBufferToJSON = JSON.parse(
        new TextDecoder().decode(response.response.data as ArrayBuffer)
      );

      log.error('get-download-employee-allocation-history-error', response);
      reject(convertedResponseDataFromArrayBufferToJSON);
    }
  });
};

export const getEmployeesByRoleId = async (id: number): Promise<EmployeesRolesResponse> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${ROLES_ROUTES.employeesByRoleId.generate(id)}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-employees-by-role-id-error', response);
      reject(response);
    }
  });
};

export const updateEmployeesRoles = async ({
  employeesToChange,
}: EmployeesRolesUpdateRequest): Promise<EmployeesRolesResponse> => {
  const body = {
    employeesToChange,
  };

  const response = await Api.put({
    url: `${CAPACITY_BFF_PATH}${ROLES_ROUTES.roleEmployeesChange}`,
    body,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('update-employees-roles-error', response);
      reject(response);
    }
  });
};

export const getUpdatedEmployeeInformation = async (
  employeeById: number
): Promise<EmployeeUpdateInformationResponse> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.employeeUpdateInformation.generate(employeeById)}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-updated-employee-information-error', response);
      reject(response);
    }
  });
};

export const updateEmployeeToInTransition = async ({
  employeeId,
  structureId,
}: EmployeeTransitionRequest): Promise<Response> => {
  const config = {
    params: {
      structureId,
    },
  };
  const response = await Api.patch({
    url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.employeeTransition.generate(employeeId)}`,
    config,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response);
    } else {
      log.error('update-employee-transition-error', response);
      reject(response);
    }
  });
};

export const downloadBulkPeopleTemplate = async (): Promise<void> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.bulkPeopleTemplate}`,
    config: { responseType: 'arraybuffer' },
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      downloadFileFromRoute(response);
      resolve();
    } else {
      log.error('download-bulk-people-template-error', response);
      reject(response);
    }
  });
};

export const getEmployeeGuardianInfo = async (
  employeeId: number
): Promise<GetEmployeeGuardianInfoResponse> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${EMPLOYEES_ROUTES.guardianInfo.generate(employeeId)}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-employee-guardian-info-error', response);
      reject(response);
    }
  });
};
